import React, { Component } from 'react'
import 'CSS/pages/Reservation.scss'

export default class Reservation extends Component {
   render() {
      return (
         <div className="Reservation">
            Reservation PAGE
         </div>
      )
   }
}
